<script>
import { ANNOTATIONS, EPMC_FUNDERS, PREPRINTS } from '@/config'
import { fetchSearchResults, fetchGrants } from '@/api'
import formatNum from '@/helpers/number'
import { EmbeddedVideo } from 'epmc-patterns/components/v2'
import StaticPageLayout from '@/templates/static/StaticPageLayout'
import SupportersSection from '@/templates/static/SupportersSection'

export default {
  metaInfo() {
    return {
      title: 'About - Europe PMC',
    }
  },
  components: { EmbeddedVideo, StaticPageLayout, SupportersSection },
  data() {
    return {
      title: 'About Europe PMC',
      sections: [
        {
          id: 'what-is-europe-pmc',
          heading: 'h2',
          title: 'What is Europe PMC?',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'who-is-behind-europe-pmc',
          heading: 'h2',
          title: 'Who is behind Europe PMC?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'why-use-europe-pmc',
          heading: 'h2',
          title: 'Why use Europe PMC?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'how-is-europe-pmc-used',
          heading: 'h2',
          title: 'How is Europe PMC used?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'what-is-next-for-europe-pmc',
          heading: 'h2',
          title: 'What’s next for Europe PMC?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
      searchCounts: [
        {
          query: 'SRC:*',
          count: '',
        },
        {
          query: 'HAS_FT:Y',
          count: '',
        },
      ],
      preprintServerCount: PREPRINTS.length,
      annotationTypeCount: ANNOTATIONS.length,
      funderCount: EPMC_FUNDERS.length,
      grantCounts: [
        {
          query: 'epmc_funders:yes',
          count: '',
        },
        {
          query: 'cat:COVID-19',
          count: '',
        },
      ],
      firstVideoImgUrl: require('@/assets/what-is-europe-pmc.jpeg'),
      secondVideoImgUrl: require('@/assets/how-researchers-are-using-europe-pmc.jpg'),
    }
  },
  mounted() {
    const { searchCounts, grantCounts } = this

    searchCounts.forEach((count) => {
      fetchSearchResults({ query: count.query }).then((response) => {
        count.count = formatNum(response.hitCount)
      })
    })

    grantCounts.forEach((count) => {
      fetchGrants({ query: count.query, page: 999999 }).then((response) => {
        count.count = formatNum(+response.HitCount)
      })
    })
  },
}
</script>
<template>
  <static-page-layout id="about-page" :title="title" :sections="sections">
    <p id="section-start" slot="section-start-slot" class="h3 regular">
      Europe PMC is at the heart of open science. We partner with other
      organisations to build robust public tools to provide access to open
      content and data, and to advance life sciences research.
    </p>
    <template slot-scope="{ section }">
      <template v-if="section.id === 'what-is-europe-pmc'">
        <p>
          Europe PMC provides comprehensive access to life sciences literature
          from trusted sources. It's available to anyone, anywhere for free.
          With Europe PMC you can search and read
          <a :href="'/search?query=' + searchCounts[0].query"
            >{{ searchCounts[0].count }} publications, preprints and other
            documents</a
          >
          enriched with links to supporting data, reviews, protocols, and other
          relevant resources.
        </p>
        <embedded-video
          vid="2jyY9_hnpB8"
          duration="1:52"
          title="What is Europe PMC?"
          :image-url="firstVideoImgUrl"
        ></embedded-video>
      </template>

      <template v-else-if="section.id === 'who-is-behind-europe-pmc'">
        <p>
          Europe PMC is hosted by EMBL’s European Bioinformatics Institute (<a
            href="//www.ebi.ac.uk/"
            >EMBL-EBI</a
          >), an international, innovative and interdisciplinary research
          organisation which aims to make the world’s public biological data
          freely available to the scientific community. Europe PMC is partnered
          with
          <a href="//www.ncbi.nlm.nih.gov/labs/pmc/about/pmci/"
            >PubMed Central (PMC)</a
          >, and endorsed and supported by a group of
          <a href="/Funders">international science funders</a> as their
          repository of choice.
        </p>
        <div id="partners-logos">
          <a href="//www.ebi.ac.uk" aria-label="EMBL-EBI">
            <img id="ebi-logo" src="@/assets/embl-ebi.jpg" alt="EMBL-EBI" />
          </a>
          <a href="//www.ncbi.nlm.nih.gov/labs/pmc/">
            <img
              id="pubmed-central-logo"
              src="@/assets/pubmed-central.png"
              alt="PubMed Central"
            />
          </a>
        </div>
        <p>
          Europe PMC works closely with partner organisations to build open,
          full text scientific literature resources and support innovation by
          engaging users, enabling contributors, and integrating related
          research data.
        </p>
      </template>

      <template v-else-if="section.id === 'why-use-europe-pmc'">
        <p class="h3">
          Access a wealth of life sciences research, including preprints
        </p>
        <p>
          Europe PMC contains
          <a :href="'/search?query=' + searchCounts[0].query"
            >{{ searchCounts[0].count }} abstracts</a
          >
          and
          <a :href="'/search?query=' + searchCounts[1].query"
            >{{ searchCounts[1].count }} full text articles</a
          >, including research articles, preprints, micropublications, books,
          reviews, and protocols. Europe PMC integrates content from a range of
          sources such as PubMed, PubMed Central (PMC), Agricola, and
          {{ preprintServerCount }} life science preprint servers including
          biorxiv, Research Square, and Preprints.org. See
          <a href="/Preprints">Preprints in Europe PMC</a> for more information
          about the integration of preprints, and the COVID-19 preprints full
          text initiative. When the full text is not available, Europe PMC can
          provide extended access to full text, with links to legal free copies
          via
          <a
            href="http://blog.europepmc.org/2018/04/unlocking-open-europe-pmc-integrates.html"
            >Unpaywall</a
          >.
        </p>

        <p class="h3">
          Connect research articles with other research resources
        </p>
        <p>
          Research articles in Europe PMC are linked to data behind the paper,
          which supports reproducibility. Articles are also linked to protocols,
          smart citations, peer review materials and more. Preprints versions
          are linked to each other, and to the journal published version, when
          possible.
        </p>
        <div id="pub-types-image">
          <img src="@/assets//pub-types.svg" alt="Publication types" />
          <span id="label1"
            >{{ searchCounts[0].count }}<br />research<br />articles</span
          >
          <span id="label2">External content<br />& data resources</span>
          <span id="label3"
            >Research<br />enriched<br />with relevant<br />resources</span
          >
        </div>

        <p class="h3">Examine data behind the paper</p>
        <p>
          Europe PMC connects publications with high quality data in over 40
          biological databases. This includes data that supports the results of
          the publication, so that you can examine the evidence behind
          scientific claims and for reproducibility of the results. Europe PMC
          also includes links to curated biological resources, where you can
          find out more about the data that cites the publication. Learn more
          about Data in <a href="/Help">Help using Europe PMC</a>.
        </p>

        <p class="h3">Use intuitive and powerful search tools</p>
        <p>
          Based on the latest technologies Europe PMC search helps you discover
          research evidence even when it is buried deep in the literature.
        </p>
        <p>
          Compared to other search engines, Europe PMC searches both
          <a :href="'/search?query=' + searchCounts[0].query"
            >abstracts and full text of over
            {{ searchCounts[0].count }} articles</a
          >, allowing you to find a greater number of relevant results. Our
          high-performance search tools allow you to customize your queries,
          enabling you to search specific parameters such as cited articles;
          articles with open peer reviews or extended protocols; searches
          limited to clinical trials or preprints, or even to specific article
          sections, such as Methods or Figures. They help you search for data
          behind publications and find papers providing biological models,
          citing proteomics datasets, or describing protein structures. See
          <a href="/advancesearch">Advanced search</a> and
          <a href="/searchsyntax">Search syntax reference</a> for more search
          options.
        </p>
        <p>
          In addition, Europe PMC offers the
          <a href="/grantfinder">Grant finder</a> tool to enable searching for
          {{ grantCounts[0].count }} biomedical grants from
          {{ funderCount }} life science funders, as well as
          {{ grantCounts[1].count }} grants awarded for COVID-19 related
          projects.
        </p>

        <p class="h3">Showcase your work</p>
        <p>
          Europe PMC supports researchers with author profile pages that
          showcase and promote the author's scholarly work. The author profile
          provides a graphical overview of published articles and preprints,
          their open access status, as well as their citation rate over time.
          Author profiles are based on the author's ORCID record. An
          <a href="//orcid.org">ORCID</a> is a unique identifier for research
          authors. The use of ORCID in Europe PMC makes it possible to refine
          the author search, and receive suggested authors based on their ORCID
          record. You can also add articles to your ORCID profile using Europe
          PMC’s <a href="/orcid/import">ORCID claiming tool</a>.
        </p>
        <p>
          Europe PMC operates a manuscript submission system (<a
            href="//plus.europepmc.org"
            >Europe PMC plus</a
          >) on behalf of Europe PMC funders, who recommend or require
          publications from the research they fund to be made openly available
          via Europe PMC. Authors supported by Europe PMC funders can deposit
          their manuscripts for inclusion in Europe PMC and by extension in
          PubMed Central (PMC) using Europe PMC plus.
        </p>

        <p class="h3">Find annotated biological concepts</p>
        <p>
          Europe PMC uses text mining and machine learning techniques to
          annotate literature with relevant biological terms and concepts, from
          chemicals and cell lines, to gene mutations and protein interactions.
          You can find biological terms in the text by using the Annotations
          tool to select and find terms for highlighting. Over 1.3 billion
          annotations covering 42 types of concepts are available. Find out more
          about <a href="/Help#keyterms">Annotations</a>.
        </p>

        <p class="h3">Get results with powerful programmatic tools</p>
        <p>
          Europe PMC provides programmatic access to open content and metadata
          for building diverse literature-based applications. Europe PMC offers
          APIs for accessing publications and related information via the
          <a href="/RestfulWebService">Articles API</a>, grant information from
          Europe PMC funders and COVID-19 related grants via the
          <a href="/GristAPI">Grants API</a>, and text-mined annotations
          contained in abstracts and open access articles via the
          <a href="/AnnotationsApi">Annotations API</a>. See
          <a href="/developers">Developer resources</a>
          for a full list of services provided. To make it easier to
          programmatically search and retrieve specific research outputs, Europe
          PMC integrates persistent identifiers, such as ORCIDs, Grant DOIs and
          RORs.
        </p>
        <p>
          If you would like to see examples of how Europe PMC APIs can be used
          to create new products and services, please visit
          <a href="/api-case-studies">API case studies</a>.
        </p>
        <p>
          Europe PMC strongly supports open source development. Open source
          projects of particular interest to the user community are available on
          Europe PMC
          <a href="//gitlab.ebi.ac.uk/literature-services/public-projects"
            >GitLab</a
          >
          and <a href="//github.com/EuropePMC">GitHub</a> pages.
        </p>

        <p class="h3">Take advantage of text-mining resources</p>
        <p>
          For the text-mining community, a range of Europe PMC resources are
          available as <a href="/downloads">bulk downloads</a> via the FTP site:
        </p>
        <ul>
          <li>
            <a href="//europepmc.org/ftp/pmclitemetadata/">Metadata</a> for all
            full text articles
          </li>
          <li>
            <a href="/downloads/openaccess">Open access subset</a> containing
            the full text open access articles from PubMed Central, along with
            supplemental files and figures
          </li>
          <li>
            <a href="/downloads/preprints">Preprints subset</a> containing
            preprint abstracts and full text for preprints on COVID-19
          </li>
          <li>
            <a href="//europepmc.org/ftp/TextMinedTerms">Accession numbers</a>
            from various life science databases text-mined from publications.
          </li>
        </ul>
        <p>
          To make text-mining advances widely available for the benefit of the
          research community Europe PMC has developed a dedicated
          <a href="/AnnotationsSubmission">Annotations Submission System</a>. It
          allows expert text-mining providers to share their results on the
          Europe PMC website via the
          <a href="/Annotations#using-annotations">Annotations panel</a> and
          programmatically through the
          <a href="/AnnotationsApi">Annotations API</a>. The system can also
          accept relevant statements manually curated by dedicated biocuration
          groups.
        </p>
      </template>
      <template v-else-if="section.id === 'how-is-europe-pmc-used'">
        <p>
          Millions of researchers, curators and librarians use Europe PMC for
          their literature research. Here is why:
        </p>
        <blockquote>
          I do recommend Europe PMC to the researchers that I meet, because it
          gives you a better experience than some other search tools for the
          literature, and you get the full text with it, and it has a lot of
          added extras, linking out to resources that are relevant.
          <p class="blockquote-source">
            Naomi Penfold, Associate Director, ASAPbio
          </p>
        </blockquote>
        <blockquote>
          To be able to search both the published literature and preprints with
          the same search terms and in one go that's the real advantage.
          <p class="blockquote-source">
            Frank Uhlmann, Group Leader, Francis Crick Institute
          </p>
        </blockquote>
        <embedded-video
          vid="L9Xzn0Y0rg4"
          duration="5:06"
          title="How researchers are using Europe PMC"
          :image-url="secondVideoImgUrl"
        ></embedded-video>
        <p>
          Europe PMC APIs have been used by programmatic users from a host of
          external parties to do large scale literature analyses, and build
          diverse applications. These include academic institutes, commercial
          industries, publishers, funders, infrastructure providers, text mining
          groups, open science apps and tools, and Research Information (CRIS)
          systems. Here is why:
        </p>
        <blockquote>
          We use Europe PMC API in our citation harvesting pipeline. Europe PMC
          has a well documented, reliable, and production-ready API allowing any
          user and service to build an open ecosystem of tools that empowers
          researchers.
          <p class="blockquote-source">
            Alex Loannidis, Service manager, Zenodo
          </p>
        </blockquote>
        <blockquote>
          I have worked with other literature services APIs like Scopus and WoS,
          but there is no comparison with the service Europe PMC team provides.
          <p class="blockquote-source">
            Frederico Lopez Gomez, Web developer, International Mouse
            Phenotyping Consortium
          </p>
        </blockquote>
        <p>
          Find out more about
          <a href="/api-case-studies">How people are using Europe PMC APIs</a>.
        </p>
      </template>

      <template v-else-if="section.id === 'what-is-next-for-europe-pmc'">
        <p>Europe PMC will continue to focus development in these key areas:</p>
        <ul>
          <li>
            <b>Publishing innovation</b>: Building on emerging trends in
            scholarly communication, from including preprints to integrating
            ORCIDs.
          </li>
          <li>
            <b>Reproducible science</b>: Supporting open data re-use by linking
            publications with underlying data.
          </li>
          <li>
            <b>Text and data mining</b>: Enabling data-driven discovery by
            offering more efficient ways to read and process scientific
            literature with the help of text-mining and machine learning
            technologies.
          </li>
        </ul>
        <p>
          To learn about Europe PMC’s mission and objectives, and to view plans
          for future development visit the
          <a href="/Roadmap">Europe PMC Roadmap</a>.
        </p>
        <p>
          For detailed information about Europe PMC, read the article
          <a href="/article/MED/33180112">Europe PMC in 2020</a>.
        </p>
      </template>
    </template>
    <supporters-section slot="last-section" />
  </static-page-layout>
</template>
<style scoped lang="scss">
#section-start {
  line-height: $base-unit * 9;
}
#ebi-logo {
  margin-right: $base-unit * 8;
}
#partners-logos {
  display: flex;
  align-items: flex-start;
  #pubmed-central-logo {
    width: $base-unit * 45;
  }
}
#pub-types-image {
  position: relative;
  span {
    position: absolute;
    text-align: center;
    font-weight: 600;
    font-size: $base-unit * 5.75;
    line-height: 1.5;
    &#label1 {
      top: 30%;
      left: 3%;
      color: $epmc-white;
    }
    &#label2 {
      top: 30%;
      left: 30%;
    }
    &#label3 {
      top: 25%;
      right: 30%;
      color: $epmc-white;
    }
    @media only screen and (max-width: $breakpoint-large) {
      &#label3 {
        right: 16%;
      }
    }
    @media only screen and (max-width: $breakpoint-med) {
      font-size: $base-unit * 5;
      &#label3 {
        right: 9%;
      }
    }
    @media only screen and (max-width: $breakpoint-small) {
      font-size: $base-unit * 3.75;
    }
    @media only screen and (max-width: $breakpoint-extra-small) {
      font-size: $base-unit * 5;
    }
    @media only screen and (max-width: 720px) {
      font-size: $base-unit * 3.75;
      &#label1 {
        top: 35%;
        left: 5%;
        color: $epmc-white;
      }
      &#label2 {
        top: 35%;
        left: 35%;
      }
      &#label3 {
        top: 28%;
        right: 11%;
      }
    }
    @media only screen and (max-width: 600px) {
      font-size: $base-unit * 3;
    }
    @media only screen and (max-width: $breakpoint-smallest) {
      font-size: $base-unit * 2;
    }
    @media only screen and (max-width: 320px) {
      font-size: $base-unit * 1.75;
    }
    @media only screen and (max-width: 280px) {
      font-size: $base-unit * 1.5;
    }
  }
}
.right {
  text-align: right;
}
.video {
  margin: ($base-unit * 8) 0;
}
</style>
